import React from "react"

const Button = ({ children, callback, textColor, bgColor }) => {
  return (
    <button
      type={"button"}
      onClick={callback}
      className={`group relative outline-none overflow-hidden flex h-full ${
        bgColor || "bg-primary"
      } transition-all delay-50 hover:bg-white hover:scale-105 focus:bg-white focus:scale-105 ${
        textColor || "hover:text-primary focus:text-primary"
      }`}
    >
      <span className="absolute bottom-full left-0 w-full aspect-square bg-white transform-gpu duration-200 group-hover:-rotate-45 group-hover:translate-y-1/3 group-hover:-translate-x-1/2 group-focus:-rotate-45 group-focus:translate-y-1/3 group-focus:-translate-x-1/2"></span>
      <span className="absolute top-full left-0 w-full aspect-square bg-white transform-gpu duration-200 group-hover:-rotate-45 group-hover:-translate-y-1/3 group-hover:translate-x-1/2 group-focus:-rotate-45 group-focus:-translate-y-1/3 group-focus:translate-x-1/2"></span>
      <div className="z-50 w-full h-full py-4 px-12 text-center">
        {children}
      </div>
    </button>
  )
}

export default Button
