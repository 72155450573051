import React, { useState, useEffect } from "react"

const Card = ({ callback, children }) => {
  const [animated, setAnimated] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setAnimated(true)
    }, 500)
  }, [])
  return (
    <section
      className="group flex flex-col w-full h-max overflow-hidden font-a4speed"
      onClick={callback}
    >
      <div
        className={`border-transparent border-t-2 group-hover:border-t-4 group-hover:border-white group-focus-within:border-t-4 group-focus-within:border-white transform-gpu duration-200 translate-x-0 ${
          animated
            ? "translate-x-2/3 group-hover:-translate-x-2/3 group-focus-within:-translate-x-2/3 border-primary"
            : ""
        }`}
      ></div>
      <div className={`w-full flex flex-row justify-between`}>
        <div
          className={`border-transparent border-l-2 group-hover:border-l-4 group-hover:border-white group-focus-within:border-l-4 group-focus-within:border-white transform-gpu duration-200 translate-y-0 ${
            animated
              ? "translate-y-2/3 group-hover:-translate-y-2/3 group-focus-within:-translate-y-2/3 border-primary"
              : ""
          }`}
        ></div>
        {/** Actual card content */}
        <div
          className={`relative w-full flex items-center p-4 bg-slate-800 rounded-tl-xl rounded-br-xl group-hover:rounded-tl-none group-focus-within:rounded-tl-none group-hover:rounded-br-none group-focus-within:rounded-br-none group-hover:rounded-bl-xl group-focus-within:rounded-bl-xl group-hover:rounded-tr-xl group-focus-within:rounded-tr-xl overflow-hidden transform-gpu duration-200`}
        >
          {children}
        </div>
        <div
          className={`border-transparent border-r-2 group-hover:border-r-4 group-hover:border-white group-focus-within:border-r-4 group-focus-within:border-white transform-gpu duration-200 translate-y-0 ${
            animated
              ? "-translate-y-2/3 group-hover:translate-y-2/3 group-focus-within:translate-y-2/3 border-primary"
              : ""
          }`}
        ></div>
      </div>

      <div
        className={`border-transparent border-b-2 group-hover:border-b-4 group-hover:border-white group-focus-within:border-b-4 group-focus-within:border-white transform-gpu duration-200 translate-x-0 ${
          animated
            ? "-translate-x-2/3 group-hover:translate-x-2/3 group-focus-within:translate-x-2/3 border-primary"
            : ""
        }`}
      ></div>
    </section>
  )
}
export default Card

export const CarCard = ({ car }) => {
  const [flipped, setFlipped] = useState(false)

  return (
    <Card callback={() => setFlipped(!flipped)}>
      <button className="h-44">
        {/** Card front */}
        <div
          className={`absolute w-full h-full flex flex-col items-center top-0 left-0 place-content-center transition-transform duration-500 ${
            flipped ? "-translate-x-full" : ""
          }`}
        >
          {/** Image */}
          <div className="w-full h-32 flex items-center justify-center">
            <img src={car.image} alt={car.model} />
          </div>
          {/** Model */}
          <div className="w-full text-center group-hover:text-primary z-50">
            {car.model}
          </div>
        </div>
        {/** Card back */}
        <div
          className={`absolute inset-0 w-full h-full flex flex-col items-center top-0 left-0 place-content-center p-4 transition-transform duration-500 ${
            flipped ? "" : "translate-x-full"
          }`}
        >
          {/** Parameters */}
          <div className="flex flex-col items-center justify-start w-min">
            <div className="w-full justify-start flex flex-row gap-4">
              <span className="text-primary">Brand:</span>
              <span className="text-white text-start">{car.brand}</span>
            </div>
            <div className="w-full justify-start flex flex-row gap-4">
              <span className="text-primary">Model:</span>
              <span className="text-white text-start">{car.model}</span>
            </div>
            <div className="w-full justify-start flex flex-row gap-4">
              <span className="text-primary">Rarity:</span>
              <span className="text-white text-start">{car.rarity}</span>
            </div>
            <div className="w-full justify-start flex flex-row gap-4">
              <span className="text-primary">Level:</span>
              <span className="text-white text-start">{car.level}</span>
            </div>
            <div className="w-full justify-start flex flex-row gap-4">
              <span className="text-primary">Speed:</span>
              <span className="text-white text-start">
                {car.speed}Km<span className="font-sans italic">/</span>h
              </span>
            </div>
          </div>
        </div>
      </button>
    </Card>
  )
}
