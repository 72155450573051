import React, { useState, useEffect } from "react"

export const BorderedContainer = ({ children, hasOverflow }) => {
  const [animated, setAnimated] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setAnimated(true)
    }, 500)
  }, [])

  return (
    <section
      className={`relative flex flex-col ${
        hasOverflow ? "" : "overflow-hidden"
      }`}
    >
      <div
        className={`border-transparent border-t-4 transform-gpu duration-200 translate-x-full ${
          animated ? "md:translate-x-0 md:border-white" : ""
        }`}
      ></div>
      <div className={`w-full flex flex-row justify-between`}>
        <div
          className={`border-transparent border-l-4 transform-gpu duration-200 -translate-y-full ${
            animated ? "md:translate-y-0 md:border-white" : ""
          }`}
        ></div>
        <div className={`w-full`}>{children}</div>
        <div
          className={`border-transparent border-r-4 transform-gpu duration-200 translate-y-full ${
            animated ? "md:translate-y-0 md:border-white" : ""
          }`}
        ></div>
      </div>
      <div
        className={`border-transparent border-b-4 transform-gpu duration-200 -translate-x-full ${
          animated ? "md:translate-x-0 md:border-white" : ""
        }`}
      ></div>
    </section>
  )
}
