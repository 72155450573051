export const changeNetwork = (provider, chainData) => {
  provider
    .request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: chainData.chainId }],
    })
    .catch(async error => {
      if (error.code === 4001) changeNetwork(provider, chainData)
      else if (error.code === 4902) {
        await addtNetwork(provider, chainData)
        changeNetwork(provider, chainData)
      }
    })
}

export const addtNetwork = async (provider, chainData) => {
  try {
    await provider.request({
      method: "wallet_addEthereumChain",
      params: [chainData],
    })
  } catch (error) {
    if (error.code === 4001) await addtNetwork(provider, chainData)
  }
}
