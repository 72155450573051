import React from "react"
import { CarCard } from "../elements/cards"

const Inventory = ({ inventory }) => {
  return inventory?.length ? (
    <div
      className={`w-full grid grid-cols-1 ${
        inventory.length > 1 ? "md:grid-cols-2" : ""
      } gap-4`}
    >
      {inventory?.map(car => (
        <CarCard car={car} />
      ))}
    </div>
  ) : (
    <p className="w-full py-6 text-center">There are no items to show</p>
  )
}

export default Inventory
